import lozad from 'lozad';

import MotionSections from './motion-sections';

import konamiCode from '../components/konami-code';
import Header from '../components/header';
import Typewriter from '../components/typewriter';
import Fluxogram from '../components/fluxogram';
import LOCAL_STORAGE_QUERY_KEY from '../constants';

class BasePage extends MotionSections {
  constructor() {
    super();

    this.startLazyload();
    this.startHeader();
    this.startTypewriter();
    this.startKonamiCode();
    this.storeQueryString();
    this.appendQueryStringToSignupURL();
  }

  startLazyload() {
    const observer = lozad();
    observer.observe();
  }

  startKonamiCode() {
    window.addEventListener('keydown', konamiCode);
  }

  startHeader() {
    const header = new Header(window.document.querySelector('#header'), window);
  }

  startFluxogram(selector = '[data-slide="container"]') {
    this.fluxogram = new Fluxogram({
      selector
    });
  }

  startTypewriter() {
    const element = window.document.querySelector('[data-typed]');
    const dataAttributes = element && element.dataset;
    const strings = dataAttributes && dataAttributes.typedStrings.split(';');
    const typeSpeed = dataAttributes && Number(dataAttributes.typedSpeed);
    const backSpeed = dataAttributes && Number(dataAttributes.backSpeed);

    this.typewriter = dataAttributes && new Typewriter({
      element,
      options: {
        strings,
        typeSpeed,
        backSpeed
      }
    });
  }

  storeQueryString() {
    if(window.location.search) {
      window.localStorage.setItem(LOCAL_STORAGE_QUERY_KEY, window.location.search);
    }
  }

  appendQueryStringToSignupURL() {
    const queryToAppend = window.location.search || localStorage.getItem(LOCAL_STORAGE_QUERY_KEY)
    
    if(queryToAppend) {
      const links = document.querySelectorAll('[href="/contas/criar/"]');
  
      for (let i = 0; i < links.length; i++) {
        links[i].href += queryToAppend;
      }
    }
  }
}

export default BasePage;
