import Menu from './menu';
import Toggle from './toggle';

import checkLoggedUser from '../graphql';

export default class Header {
  constructor(element, window) {
    this.element = element;
    this.window = window;
    this.scrollValue = 0;

    // Create header actions
    this.window.onscroll = this._toggleHeaderScroll.bind(this);
    this.window.onscroll();

    this._scrollEvent();
    this._createMobileMenu();
    this._createMobileSubmenus();
    this._checkLoggedUser();
  }

  _scrollEvent() {
    window.addEventListener('scroll', this._handleScrollBehavior.bind(this));
  }

  _handleScrollBehavior(event) {
    const currentScroll = event.target.scrollingElement.scrollTop;
    const scrollTop = currentScroll < this.scrollValue || currentScroll <= 0;
    this.scrollValue = event.target.scrollingElement.scrollTop;
    
    this.element.classList[scrollTop ? 'remove' : 'add']('scroll-down');
  }

  _createMobileMenu() {
    const toggle = this.window.document.querySelector('[data-toggle]');
    const toggleElements = this.window.document.querySelectorAll('[data-toggle-element]');
    const logo = this.window.document.querySelector('[data-logo]');
    const header = this.window.document.querySelector('[data-header]');

    this.mobileMenu = new Menu({toggle, elements: toggleElements, logo, header});
  }

  _createMobileSubmenus() {
    const toggle = this.window.document.querySelector('[data-toggle-submenu]');
    const toggleElements = this.window.document.querySelectorAll('[data-toggle-submenu-elements]');

    this.mobileSubmenu = new Toggle({toggle, elements: toggleElements});
  }

  _toggleHeaderScroll() {
    this.element.dataset.scrolled = this.window.scrollY > 0;
  }

  _changeHeaderForLoggedUser({user, appHref = '/olar'}) {
    const login = this.element.querySelector('a[data-login-link]');
    const button = this.element.querySelector('a[data-signup-link]');

    button.href = appHref;
    login.href = '#';

    button.textContent = button.dataset.loggedText;
    login.textContent = `${login.dataset.loggedText} ${user}`;

    login.classList.remove('hover-link');
    login.classList.add('unhover-link');
  }

  _checkLoggedUser = () =>
    checkLoggedUser()
      .then(
        ({data}) =>
          data.user &&
          this._changeHeaderForLoggedUser({user: data.user.firstName})
      )
      .catch(err => err);
}
