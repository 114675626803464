// API Docs: https://developers.google.com/youtube/iframe_api_reference?hl=pt-br

import {DEFAULT_DOM_SELECTORS, VIDEO_ATTRIBUTES} from './config';
import mapVideoFuncionalities from './mappers';
import bindVideoActions, {resizePlayer} from './actions';

import screens from '@loggi/ui/components/utils/screens';

export default function video({name, section = '', options = {}}) {
  const container = section && document.querySelector(section);
  const element = name ? document.querySelector(name) : container.querySelector(DEFAULT_DOM_SELECTORS.player);
  const hasVideoOptions = element.dataset.videoOptions;
  const videoOptions = hasVideoOptions && hasVideoOptions.split(';');
  const mappedFuncionalities = videoOptions && mapVideoFuncionalities(videoOptions) || {};
  const type = element.dataset && element.dataset.videoType;

  const id = element.dataset.videoId || options.videoId;
  const loop = videoOptions.includes(VIDEO_ATTRIBUTES.LOOP);
  const autoplay = videoOptions.includes(VIDEO_ATTRIBUTES.AUTOPLAY);

  // stop video creation and prevent client from fetching unecessary data
  if (loop && autoplay && window.innerWidth < screens.sizes.lg) {
    return new Promise(res => res({video: name, status: 'was not created because screen is smaller than LG breakpoint'}));
  }

  resizePlayer({aspect: options.aspect, resizeContainer: element.parentNode, sectionContainer: container});

  return new Promise(res => {
    const timer = window.setInterval(() => {
      if (window.YT && window.YT.Player) {
        const playerOptions = {
          videoId: id,
          width: '100%',
          height: '100%',
          playerVars: {
            showinfo: 0,
            controls: 0,
            modestbranding: 1,
            autohide: 1,
            rel: 0,
            playlist: loop && id,
            version: 3,
            loop: loop,
            ...options.playerVars
          },
          ...options,
          events: {
            ...mappedFuncionalities,
            onReady: mappedFuncionalities.onReady ? event => mappedFuncionalities.onReady(event, container) : () => {},
            onStateChange: mappedFuncionalities.onStateChange ? event => mappedFuncionalities.onStateChange(event, container) : () => {},
            ...options.events
          }
        };

        const player = new window.YT.Player(element, playerOptions);

        window.clearInterval(timer);

        if (container && type === 'section') {
          bindVideoActions(container, player);
        }

        res(player);
      }
    }, 500);
  });
}
