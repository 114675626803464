// Import swiper min css to bundle it
import 'swiper/dist/css/swiper.min.css';
import Swiper from 'swiper';

import screens from '@loggi/ui/components/utils/screens';

export const DEFAULT_OPTIONS = {
  slidesPerView: 2,
  breakpoints: {
    [screens.sizes.md]: {
      slidesPerView: 'auto',
      spaceBetween: -40
    }
  },
  on: {
    beforeResize() {
      if (window.innerWidth <= screens.sizes.md) {
        this.slides.css('width', '');
      }
    }
  }
};

export default function Slide({selector, options = {}}) {
  return new Swiper(selector, {...DEFAULT_OPTIONS, ...options});
}
