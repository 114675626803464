import Toggle from './toggle';

export default class Menu extends Toggle {
  constructor({toggle, elements, logo, header}) {
    const onToggle = status => {
      this._toggleIcon.bind(this)(status);
      this._toggleLogo.bind(this)(status);
      this._toggleHeader.bind(this)(status);
    };

    super({toggle, elements, onToggle});

    this.logo = logo;
    this.header = header;
  }

  _toggleIcon(status) {
    this.toggle.dataset.active = status;
  }

  _toggleLogo(status) {
    this.logo.dataset.active = status;
  }

  _toggleHeader(status) {
    this.header.dataset.active = status;
  }
}
