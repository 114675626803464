import BasePage from './base-page';

import Video from '../components/video';
import VideoNative from '../components/video/native';

class CompanyPage extends BasePage {
  constructor() {
    super();

    this.isMobileDevice = this.detectMobileDevice();
    if(!this.isMobileDevice) {
      VideoNative(window.document.querySelector('.highlight-video'));
    }
  }

  detectMobileDevice() {
    return window.navigator.userAgent.includes('Mobile');
  }

  createVideoSection(sectionName = 'deliveries') {
    const section = `[data-video-section="${sectionName}"]`;
    new Video({section}).then(player => {
      this[`video${sectionName}`] = player;
    });
  }
}

export default CompanyPage;
