const konami = {
  keys: {
    left: 37,
    up: 38,
    right: 39,
    down: 40,
    a: 65,
    b: 66
  },
  code: ['up', 'up', 'down', 'down', 'left', 'right', 'left', 'right', 'b', 'a'],
  completed: 0
};

const motoca = document.querySelector('[data-motoca]');

const animationDurationMs = 5000;

function freiaOMotoca() {
  motoca.dataset.grau = false;
}

function aceleraOMotoca() {
  motoca.dataset.grau = true;
}

export default function doTheKonamiCode(e) {
  const {keyCode} = e;
  const body = document.body.innerHTML.toString();

  const nextKey = konami.code[konami.completed];
  const nextKeyCode = konami.keys[nextKey];
  konami.completed = nextKeyCode === keyCode ? (konami.completed + 1) : 0;

  if (konami.completed === konami.code.length) {
    konami.completed = 0;
    aceleraOMotoca();

    window.setInterval(freiaOMotoca, animationDurationMs);
  } else {
    freiaOMotoca();
  }
}
