import {clearVideoContainer, resetVideoContainer} from './actions';

// Default DOM selectors ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
export const DEFAULT_DOM_SELECTORS = {
  player: '[data-video]',
  playButton: '[data-video-button="play"]',
  overlay: '[data-video-overlay]'
};

const DEFAULT_BANNER_ANIMATION_TIME_SECONDS = 3;

// API funcionalities ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
export const autoPlayFn = (event, container) => {
  event.target.playVideo();

  const endTime = (event.target.getDuration() - DEFAULT_BANNER_ANIMATION_TIME_SECONDS) * 1000;
  window.setTimeout(() => resetVideoContainer(container), endTime);
};

export const resetAfterPlayFn = (event, container) => event.data === 0 && resetVideoContainer(container)

export const switchStateChange = (event, container) => {
  switch(event.data) {
    case 0:
      resetVideoContainer(container);
      break;
    case 1:
      if(container) {
        window.setTimeout(() => clearVideoContainer(container), (DEFAULT_BANNER_ANIMATION_TIME_SECONDS * 1000));
      }
      break;
  }
}

// Attributes for API :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
export const VIDEO_ATTRIBUTES = {
  AUTOPLAY: 'autoplay',
  RESET_AFTER_PLAY: 'reset-after-play',
  LOOP: 'loop'
};

export const VIDEO_FUNCTIONS = {
  AUTOPLAY: {
    onReady: autoPlayFn,
    onStateChange: switchStateChange
  },
  RESET_AFTER_PLAY: {
    onStateChange: switchStateChange
  }
};
