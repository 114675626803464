class MotionSections {
  constructor() {
    this.motionSections = [...document.querySelectorAll('[data-motion-section]')];

    if (this.motionSections.length) {
      this.screenHeight = window.innerHeight;
      this.animateOnScrollAt = this.screenHeight / 2;

      this.doTheMotion();
      this.watchScroll();
    }
  }

  updateMotionStatus = (section, status = false) => {
    section.dataset.motionActive = status;

    if (status) {
      const motionDelay = Number(section.dataset.motionDelay) || 0;
      const motionBlocks = [...section.querySelectorAll('[data-motion-block]')];

      motionBlocks.forEach((block, index) => setTimeout(() => {
        block.dataset.motionActive = true;
      }, (index * motionDelay)));

      this.motionSections = this.motionSections.filter(eachSection => eachSection !== section);
    }
  };

  doTheMotion = () => {
    for (const section of this.motionSections) {
      const sectionOffset = section.getBoundingClientRect().top;
      this.updateMotionStatus(section, sectionOffset <= this.animateOnScrollAt);
    }
  };

  checkMotion = () => {
    if (this.motionSections.length) {
      this.doTheMotion();
    } else {
      window.removeEventListener('scroll', this.checkMotion);
    }
  };

  watchScroll = () => {
    window.addEventListener('scroll', this.checkMotion);
  };
}

export default MotionSections;
