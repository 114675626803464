import {DEFAULT_DOM_SELECTORS} from './config';

const updateVideoSize = ({resizeContainer, sectionContainer, aspect:{width, height}}) => {
  const sectionContainerWidth = sectionContainer.offsetWidth;
  const sectionContainerHeight = sectionContainer.offsetHeight;

  if (width > height) {
      resizeContainer.style.width = `${sectionContainerWidth}px`;
      resizeContainer.style.height = `${sectionContainerWidth * (height / width)}px`;
  } else {
    resizeContainer.style.width = `${sectionContainerHeight * (width / height)}px`;
    resizeContainer.style.height = `${sectionContainerHeight}px`;
  }
};

export const resizePlayer = (options) => {
  if (options.aspect && options.resizeContainer && options.sectionContainer) {
    window.addEventListener('resize', () =>  updateVideoSize(options));
    updateVideoSize(options);
  }
};

export const clearVideoContainer = (container) => {
  container.dataset.active = true;
};

export const resetVideoContainer = (container) => {
  container.dataset.active = false;
};

const playVideo = (container, player) => {
  player.playVideo();
  clearVideoContainer(container);
};

const pauseVideo = (container, player) => {
  resetVideoContainer(container);
  player.pauseVideo();
}

export default function bindVideoActions(container, player) {
  const button = container.querySelector(DEFAULT_DOM_SELECTORS.playButton);
  const overlay = container.querySelector(DEFAULT_DOM_SELECTORS.overlay);

  button.onclick = () => playVideo(container, player);
  overlay.onclick = () => pauseVideo(container, player);
}
