import CompanyPage from './classes/company-page';

import Slide from './components/slide';
import VideoNative from './components/video/native';

(function(window) {
  class Home extends CompanyPage {
    constructor(window) {
      super();
      const DOCUMENT = window.document;

      this.startSlide();
      this.startControlVideo();
    }

    startSlide() {
      this.slide = new Slide({
        selector: '[data-slide="container"]',
        options: {
          navigation: {
            nextEl: '[data-slide="next"]',
            prevEl: '[data-slide="prev"]',
            disabledClass: 'btn-disabled'
          }
        }
      });
    }

    startControlVideo() {
      if (!this.isMobileDevice) {
        VideoNative(window.document.querySelector('#home-control .video-wrapper'));
      }
    }
  }

  const home = new Home(window);
})(window);
